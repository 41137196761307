import { useCallback, useMemo, useState } from "react";

import Constants from "../../common/Constants";
import Tabs from "../../components/common/Tabs";
import colors from "../../assets/colors";
import { capitalizeFirstLetter } from "../../common/Utils";
import useGamification from "../../hooks/useGamification";
import { useAuth } from "../../providers/useAuth";
import { Roles } from "../../types/Role";
import useModal from "../../hooks/useModal";
import CustomButton from "../../components/common/CustomButton";
import { useNavigate } from "react-router-dom";
import { Carpool } from "../../types/Carpool";
import ControlledPicker from "../../components/common/ControlledPicker";
import useUser from "../../hooks/useUser";
import { useFieldArray, useForm } from "react-hook-form";
import { User } from "../../types/User";
import ControlledInput from "../../components/common/ControlledInput";
import { MdClose } from "react-icons/md";
import ControlledDatePicker from "../../components/common/ControlledDatePicker";
import { tailwindColors } from "../../assets/tailwindColors";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const chartColors = [
  tailwindColors.teal[700],
  tailwindColors.sky[700],
  tailwindColors.rose[700],
  tailwindColors.gray[700],
];

type FieldValues = {
  user_id: string;
  carpools: { distance: number; passengers: string[]; date: Date }[];
  gamification_cards: { ids: [] }[];
};

const GamificationSimulator = () => {
  const navigate = useNavigate();
  const { userData } = useAuth();
  const { useCorporateUsers } = useUser();
  const { template } = useGamification();
  const [category, setCategory] = useState("all");

  const {
    control,
    formState: { errors },
    watch,
    register,
  } = useForm<FieldValues>({
    defaultValues: {
      carpools: [{ distance: undefined }],
      gamification_cards: [{ ids: [] }],
    },
  });

  const {
    fields: carpoolFields,
    append: appendCarpool,
    remove: removeCarpool,
  } = useFieldArray({
    control,
    name: "carpools",
  });

  const {
    fields: gamificationsFields,
    append: appendGamifications,
    remove: removeGamifications,
  } = useFieldArray({
    control,
    name: "gamification_cards",
  });

  const { data: users = [] } = useCorporateUsers(userData?.corporate);

  const formData = watch();

  const selectedUser = useMemo(
    () => users.find((user) => user.id === formData.user_id),
    [formData.user_id, users]
  );

  const calculateMultiplier = useCallback(
    (index: number) => {
      const total = formData.gamification_cards[index].ids
        .map((id) => {
          const card = template?.cards.find((c) => c.id === id);
          return card?.multiplier || 1;
        })
        .reduce((t, c) => t + c, 0);

      return Math.min(total, 4);
    },
    [formData, template]
  );

  const carpoolToCredit = useCallback(
    (index: number) => {
      const carpool = formData.carpools[index];
      const co2Pkm =
        (selectedUser?.cars?.[0]?.avg_Enedc_g_km ||
          selectedUser?.cars?.[0]?.avg_Ewltp_g_km ||
          185.591) / 1000;
      const credits = calculateCredits({
        co2Pkm,
        distance: Number(carpool.distance) || 0,
        sizeOfCar: 3,
      });

      return credits * 2;
    },
    [formData.carpools, selectedUser?.cars]
  );

  const chartData = useMemo(() => {
    const multipliers = formData.gamification_cards.map((_, index) =>
      calculateMultiplier(index)
    );
    const plainData = formData.carpools.map((carpool, carpoolIndex) => {
      const credits = carpoolToCredit(carpoolIndex);
      return {
        name: `Carpool ${carpoolIndex + 1}`,
        ...multipliers
          .map((value) => value * credits)
          .reduce((t, c, i) => ({ ...t, [`gamificationValue${i}`]: c }), {}),
      };
    });

    return plainData.map((data, index, arr) => {
      const keys = Object.keys(data).filter((key) => key !== "name");

      return {
        name: data.name,
        ...keys.reduce((t, c, i) => {
          const previous = arr
            .filter((_, k) => k < index)
            .reduce((kt, kc) => kt + (kc as any)?.[c] || 0, 0);
          return { ...t, [c]: previous + (data as any)?.[c] || 0 };
        }, {}),
      };
    });
  }, [
    calculateMultiplier,
    carpoolToCredit,
    formData.carpools,
    formData.gamification_cards,
  ]);

  return (
    <div className="flex-1">
      <div
        className="p-12 overflow-y-auto"
        style={{ height: `calc(100vh - ${Constants.header_height}px)` }}
      >
        <div className="flex mb-6 items-center gap-4">
          <p className="text-2xl font-bold">Gamification Simulator</p>

          <div style={{ flex: 1 }} />
        </div>

        <div className="gap-4 flex flex-col items-start">
          {/* user */}
          <Card title="User" cardClassName="w-full" childrenClassName="w-full">
            <ControlledPicker
              containerStyle={{ width: 400 }}
              label={"Select User"}
              formProps={{ control, name: "user_id", errors }}
              options={users.map((user) => ({
                label: user.full_name || user.username || "Anonymous User",
                value: user.id,
              }))}
            />
            {selectedUser && (
              <div className="self-stretch w-[1px] bg-gray-300" />
            )}
            {selectedUser && <SelectedUserDetails user={selectedUser} />}
          </Card>

          {/* gamification cards */}
          <Card
            title="Gamification Configurations"
            cardClassName="w-full"
            childrenClassName="w-full"
          >
            <div className="grid w-full grid-cols-4 gap-4 flex-1">
              {gamificationsFields.map((field, index) => {
                return (
                  <div
                    key={field.id}
                    className="border border-dashed p-2 rounded-md flex flex-col gap-1 w-full relative"
                  >
                    <div
                      style={{ background: chartColors[index] }}
                      className="p-2 rounded-[3px] m-1"
                    >
                      <p className="text-gray-100 text-sm font-semibold">
                        Card set {index + 1}
                      </p>
                    </div>
                    <ControlledPicker
                      containerStyle={{ width: "100%" }}
                      label="Gamification Multipliers"
                      isMulti
                      formProps={{
                        control,
                        name: `gamification_cards.${index}.ids`,
                        errors,
                      }}
                      options={
                        template?.cards.map((card) => ({
                          label: card.name,
                          value: card.id,
                        })) || []
                      }
                    />
                    <MdClose
                      onClick={() => removeGamifications(index)}
                      className="absolute right-[20px] top-[22px] hover:scale-125 transition-all cursor-pointer text-gray-100"
                    />
                  </div>
                );
              })}
              {formData.gamification_cards.length < 4 && (
                <button
                  onClick={() =>
                    appendGamifications({
                      ids: [],
                    })
                  }
                  className="border border-dashed p-2 rounded-md flex gap-1 hover:bg-slate-50 items-center justify-center text-gray-400 font-bold text-4xl transition-all min-h-[93px]"
                >
                  +
                </button>
              )}
            </div>
          </Card>

          {/* carpools */}
          <Card
            title="Carpools"
            cardClassName="w-full"
            childrenClassName="w-full"
          >
            <div className="grid w-full grid-cols-4 gap-4 flex-1">
              {carpoolFields.map((field, index) => {
                return (
                  <div
                    key={field.id}
                    className="border border-dashed p-2 rounded-md flex flex-col gap-1 w-full relative"
                  >
                    <ControlledInput
                      label="Distance"
                      containerStyle={{ width: "100%" }}
                      formProps={{
                        register,
                        errors,
                        name: `carpools.${index}.distance`,
                      }}
                    />
                    <ControlledPicker
                      containerStyle={{ width: "100%" }}
                      label="Passengers"
                      isMulti
                      formProps={{
                        control,
                        name: `carpools.${index}.passengers`,
                        errors,
                      }}
                      options={users.map((user) => ({
                        label:
                          user.full_name || user.username || "Anonymous User",
                        value: user.id,
                      }))}
                    />
                    <ControlledDatePicker
                      containerStyle={{ flex: 1 }}
                      label="Carpool time"
                      formProps={{
                        errors,
                        name: `carpools.${index}.date`,
                        options: { required: true },
                        control,
                      }}
                    />
                    <MdClose
                      onClick={() => removeCarpool(index)}
                      className="absolute right-2 top-2 hover:scale-125 transition-all cursor-pointer text-gray-500"
                    />
                  </div>
                );
              })}
              <button
                onClick={() =>
                  appendCarpool({ distance: undefined, passenger: [] } as any)
                }
                className="border border-dashed p-2 rounded-md flex gap-1 hover:bg-slate-50 items-center justify-center text-gray-400 font-bold text-4xl transition-all min-h-[251px]"
              >
                +
              </button>
            </div>
          </Card>

          <Card
            title="Visualization"
            cardClassName="w-full h-[550px]"
            childrenClassName="w-full h-full pt-10"
          >
            <ResponsiveContainer
              width="100%"
              height="100%"
              style={{ marginLeft: -30 }}
            >
              <LineChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {formData.gamification_cards.map((_, index) => (
                  <Line
                    key={index}
                    type="monotone"
                    name={`Configuration ${index + 1}`}
                    dataKey={`gamificationValue${index}`}
                    stroke={chartColors[index]}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default GamificationSimulator;

const SelectedUserDetails = ({ user }: { user: User }) => {
  return (
    <div className="rounded-lg bg-gray-100 p-4 gap-1 flex-1">
      <p>
        <b>Name</b> : {user.full_name || user.username}
      </p>
      <p>
        <b>Total Carpools</b> : {user.total_carpools}
      </p>
      <p>
        <b>Total Carpool Offers</b> : {user.total_offers}
      </p>
      <p>
        <b>Total Credits</b> : {user.credits.toFixed(2)}
      </p>
    </div>
  );
};

const Card = ({
  children,
  title,
  cardClassName,
  childrenClassName,
}: {
  children: React.ReactNode;
  title: string;
  cardClassName?: string;
  childrenClassName?: string;
}) => {
  return (
    <div
      className={
        "flex flex-col items-start border rounded-[10px] p-4 " + cardClassName
      }
    >
      <div className="m-[-16px] mb-4 bg-gray-100 self-stretch flex items-start p-4 rounded-tl-lg rounded-tr-[9px]">
        <p className="font-semibold text-lg">{title}</p>
      </div>
      <div className={"flex gap-4 " + childrenClassName}>{children}</div>
    </div>
  );
};
/* 
 - enable selecting a user
 - enable selecting a bunch of gamification cards // forget about this one.. will use the main template
 - enable editing the selected gamification cards and also activating and deactivating // forget about this one.. will use the main template
 - enable creating a list of carpools > the carpools will have time, distance, vehicle type and the list of passengers
 - show the difference in carbon credits earned with and without the activated gamification cards
 - show a very simple graph of the carbon credits difference
*/

// array of carpools > array of co2 saved > array of carbon credits based on a selection of gamification cards

const getCarpoolCredits = ({
  distance,
  owner,
  passengers,
}: {
  distance: number;
  owner: string;
  passengers: string[];
}) => {
  const randValues = [1.24, 3.17, 5.22, 0.2, 4.111, 2.332];
  return randValues[Math.floor(Math.random() * randValues.length)] * 10;
};

const calculateCredits = ({
  co2Pkm,
  distance,
  sizeOfCar,
}: {
  co2Pkm: number;
  distance: number;
  sizeOfCar: number;
}) => {
  const maxPossibleCarSize = 7;
  const averageCommuteDistance = 18;
  const defaultStartingCredit = 8;

  const fuelWeight = () => {
    const maxWeight = 1.5;
    return maxWeight - co2Pkm * 7;
  };

  const carSizeWeight = sizeOfCar / maxPossibleCarSize + 1;

  const distanceWeight = distance / averageCommuteDistance + 1;

  const credits =
    defaultStartingCredit * carSizeWeight * distanceWeight * fuelWeight();

  return parseFloat(credits.toFixed(1));
};
