import { Location } from "./Location";
import { Plan } from "./Plan";
import { Roles } from "./Role";

export class User {
  constructor(data?: any, uid?: string, role = Roles.ClientCarpoolingUser) {
    this.raw = data;
    this.id = uid;
    this.role = role;
    Object.assign(this, data);
  }

  id?: string;
  role: Roles;
  profileImage?: string;
  username?: string;
  email?: string;
  full_name: string = "";
  mobile?: string;
  avatar?: string;
  city?: string;
  home_location?: Location;
  work_location?: Location;
  corporate?: string;
  corporate_team?: string;
  search_radius: number = 10;
  cars?: any[];
  raw: any;
  credits: number = 0;
  total_offers: number = 0;
  total_carpools: number = 0;
  total_co2: number = 0;
  plan_type: Plan[] = ["personal"];
  selected_plan_type?: Plan;
  location?: Location;
  type: "user" = "user";
  is_demo?: boolean
  stripe?: {
    customer_id: string;
  };
  near_wallet?: {
    account_id: string;
    all_keys: string;
    public_key: string;
  };

  preferences: {
    carpool: {
      female_passengers_only: boolean;
      verified_passengers_only: boolean;
    };
    notifications: {
      allow_notifications: boolean;
      subscriptions: {
        newsletter: boolean;
        platform_features_and_updates: boolean;
        new_quests: boolean;
        new_marketplace_items: boolean;
        upcoming_events: boolean;
      };
    };
  } = {
    carpool: {
      female_passengers_only: false,
      verified_passengers_only: false,
    },
    notifications: {
      allow_notifications: true,
      subscriptions: {
        newsletter: true,
        platform_features_and_updates: true,
        new_quests: true,
        new_marketplace_items: true,
        upcoming_events: true,
      },
    },
  };

  carpool_rules: string[] = [];

  gender?: "male" | "female" | "other";
  account_type: (typeof USER_TYPES)[keyof typeof USER_TYPES] =
    USER_TYPES.NORMAL;

  get first_name(): string {
    return this.full_name?.split(" ")?.[0];
  }

  get last_name(): string {
    return this.full_name?.split(" ")?.[1];
  }

  get user_type() {
    return this.corporate ? USER_TYPES.NORMAL : USER_TYPES.TCG_DRIVER;
  }
}

export const USER_TYPES = {
  NORMAL: "normal",
  TCG_DRIVER: "tcg_driver",
};
